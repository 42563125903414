import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import Navbar from "../components/navbar";

import BoardData from "./board/boarddata";
import BoardBio from "./board/boardbio";

export default function Board(props) {
  const { i18n } = useTranslation();

  const navitems = [
    {
      title: "Company Information",
      title_th: "ผู้จัดการกองทรัสต์",
      active: false,
      link: "/Management/REIT_Manager/Company_Information",
    },
    {
      title: "Organization Structure",
      title_th: "โครงสร้างองค์กร",
      active: false,
      link: "/Management/REIT_Manager/Organization_Structure",
    },
    {
      title: "Board of Directors",
      title_th: "คณะกรรมการบริษัท",
      active: true,
      link: "/Management/REIT_Manager/Board_of_Directors",
    },
  ];

  const initBoard = [
    {
      img: "chanin",
      name: "Chanin Donavanik",
      nameth: "ชนินทธ์ โทณวณิก",
      position: "Chairman",
      positionth: "ประธานกรรมการ",
      active: true,
    },
    {
      img: "suphajee",
      name: "Suphajee Suthumpun",
      nameth: "ศุภจี สุธรรมพันธุ์",
      position: "Director",
      positionth: "กรรมการ",
      active: false,
    },
    {
      img: "pattaneeporn",
      name: "Pattaneeporn Thienprasiddhi",
      nameth: "พัฒนีพร เธียรประสิทธิ์",
      position: "Director",
      positionth: "กรรมการ",
      active: false,
    },
    {
      img: "natharin",
      name: "Natharin Talthong",
      nameth: "ณัฐรินทร์ ตาลทอง",
      position: "Independent Director",
      positionth: "กรรมการอิสระ",
      active: false,
    },
    {
      img: "virapan",
      name: "Virapan Pulges",
      nameth: "วีรพันธ์ พูลเกษ",
      position: "Independent Director",
      positionth: "กรรมการอิสระ",
      active: false,
    },
    {
      img: "voranon",
      name: "Voranon Asavakittimetin",
      nameth: "วรนนท์ อัศวกิตติเมธิน",
      position: "Managing Director",
      positionth: "กรรมการผู้จัดการ",
      active: false,
    },
  ];

  const [boardlist, setBoardlist] = useState(initBoard);

  const boardClick = (index) => {
    let board = boardlist.map((b, i) => {
      if (i == index) {
        b.active = true;
        return b;
      } else {
        b.active = false;
        return b;
      }
    });
    setBoardlist(board);
  };

  const [lng, setLng] = useState("en");
  const [edu, setEdu] = useState([]);
  const [current, setCurrent] = useState([]);

  useEffect(() => {
    const boardlng = BoardData.filter((b) => b.lng == lng)[0].data;
    const name = boardlist.filter((b) => b.active == true)[0].img;
    const boardBio = boardlng.filter((b) => b.name == name)[0];
    setEdu(boardBio.edu);
    setCurrent(boardBio.current);
  }, [boardlist, lng]);

  useEffect(() => {
    setLng(i18n.language);
  }, [i18n.language]);

  return (
    <section className="company-info">
      <div className="mb-[150px] md:mb-[170px]">
        <Navbar navitems={navitems} />
      </div>
      <div>
        <h1 className="text-xl md:text-2xl font-cbold text-blue-950 pb-4">
          {i18n.language == "en" ? "Board of Directors" : "คณะกรรมการบริษัท"}
        </h1>
        <div className="flex justify-between max-sm:flex-col flex-wrap">
          {boardlist.map((b, i) => (
            <div className="max-md:flex max-md:flex-col sm:w-1/2 md:w-1/3 lg:w-1/6">
              <div
                className={`p-6  ${
                  b.active ? "bg-gold" : "hover:bg-neutral-100"
                } h-full`}
                onClick={() => boardClick(i)}
              >
                <img
                  src={require("./board/img/" + b.img + ".jpg")}
                  alt={b.img}
                  className="mx-auto"
                />
                <div
                  className={`${
                    b.active ? "text-white" : "text-zinc-600"
                  } font-cbold text-lg mt-6`}
                >
                  {lng == "en" ? b.name : b.nameth}
                </div>
                <div
                  className={`${
                    b.active ? "text-white" : "text-neutral-400"
                  } text-lg`}
                >
                  {lng == "en" ? b.position : b.positionth}
                </div>
              </div>
              <div className={`${b.active ? "w-full md:hidden" : "hidden"} `}>
                <BoardBio edu={edu} current={current} />
              </div>
            </div>
          ))}
          <div className={`${"hidden md:w-full md:block"}`}>
            <BoardBio edu={edu} current={current} />
          </div>
        </div>
      </div>
    </section>
  );
}
