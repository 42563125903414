import React, { useEffect, useState } from "react";

import Card from "./card";
import { useTranslation, Trans } from "react-i18next";

export default function Hero(props) {
  const { i18n } = useTranslation();

  const Img = [1, 2, 3, 4, 5];
  const [current, setCurrent] = useState(0);

  const carouselScroll = () => {
    if (current == Img.length - 1) {
      return setCurrent(0);
    }
    return setCurrent(current + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      carouselScroll();
    }, 4000);
    return () => clearInterval(interval);
  });

  return (
    <section className="hero relative bg-stone-50">
      <div className={`flex overflow-hidden h-screen w-full float-right`}>
        {Img.map((d, i) => (
          <img
            src={require("./img/image" + d + ".png")}
            alt="hero_img"
            id={"img" + d}
            className={`h-full w-[100%] min-w-full lg:min-w-2/3 object-cover object-center transition duration-500 ease-in-out`}
            style={{ transform: `translate(-${current * 100}%)` }}
            key={i}
          />
        ))}
      </div>
      <div className="relative z-50 h-screen w-full">
        <div className="h-1/2 w-full bg-gradient-to-b from-stone-50 from-10% to-transparent to-80% xl:to-70%"></div>
        <div className="h-full w-full bg-gradient-to-r from-stone-50 lg:from-20% xl:from-30% to-transparent to-50% absolute top-0 left-0 max-sm:hidden"></div>
        <div className="h-1/2 w-full bg-gradient-to-t from-stone-50 from-10% to-transparent to-80% xl:to-70%"></div>
      </div>

      <div className="absolute top-[20vh] sm:top-36 w-full z-[100]">
        <div className="max-w-[1400px] mx-auto max-2xl:px-5">
          <div className="text-blue-950 text-4xl md:text-6xl 2xl:text-7xl font-cbold pt-5 max-sm:text-center">
            DREIT
          </div>
          <div className="text-blue-950 text-2xl md:text-4xl 2xl:text-5xl max-sm:text-center">
            <Trans i18nKey="Home.Hero1">
              Dusit Thani Freehold and Leasehold
            </Trans>
            <br />
            <Trans i18nKey="Home.Hero2">Real Estate Investment Trust</Trans>
          </div>
        </div>
      </div>
      <div className="relative -top-[10vh] sm:-top-[35vh] lg:-top-[25vh] xl:-top-[20vh] 2xl:-top-[10vh] z-[200]">
        <Card />
      </div>
    </section>
  );
}
